import { INIT_PAGE_STATE } from "../../../constants/constants";

export const LOAD_MASTER_PARTNER_LIST = "LOAD_MASTER_PARTNER_LIST";
export const LOAD_MASTER_PARTNER_LIST_SUCCESS =
  "LOAD_MASTER_PARTNER_LIST_SUCCESS";
export const LOAD_MASTER_PARTNER_LIST_ERROR = "LOAD_MASTER_PARTNER_LIST_ERROR";

export const ADDEDIT_MASTER_PARTNER = "ADDEDIT_MASTER_PARTNER";
export const SAVE_ADDEDIT_MASTER_PARTNER = "SAVE_ADDEDIT_MASTER_PARTNER";
export const SAVE_ADDEDIT_MASTER_PARTNER_SUCCESS =
  "SAVE_ADDEDIT_MASTER_PARTNER_SUCCESS";
export const SAVE_ADDEDIT_MASTER_PARTNER_ERROR =
  "SAVE_ADDEDIT_MASTER_PARTNER_ERROR";
export const CLOSE_ADDEDIT_MASTER_PARTNER = "CLOSE_ADDEDIT_MASTER_PARTNER";

export const DELETE_MASTER_PARTNER = "DELETE_MASTER_PARTNER";
export const DELETE_MASTER_PARTNER_SUCCESS = "DELETE_MASTER_PARTNER_SUCCESS";
export const DELETE_MASTER_PARTNER_ERROR = "DELETE_MASTER_PARTNER_ERROR";

export const loadMasterPartnerList = (p) => {
  const param = {
    ...{
      selectedPage: 1,
      filter: null,
      orderBy: "id",
      sortedBy: "asc",
      limit: INIT_PAGE_STATE.pageSizes[0],
    },
    ...p,
  };
  return {
    type: LOAD_MASTER_PARTNER_LIST,
    payload: {
      selectedPage: param.selectedPage,
      filter: param.filter,
      orderBy: param.orderBy,
      sortedBy: param.sortedBy,
      limit: param.limit,
    },
  };
};

export const loadMasterPartnerListSuccess = (data) => ({
  type: LOAD_MASTER_PARTNER_LIST_SUCCESS,
  payload: data,
});

export const loadMasterPartnerListError = (error) => ({
  type: LOAD_MASTER_PARTNER_LIST_ERROR,
  payload: error,
});

export const addeditMasterPartner = (item, mode = "add") => ({
  type: ADDEDIT_MASTER_PARTNER,
  payload: {
    item,
    mode,
  },
});

export const closeAddeditMasterPartner = () => ({
  type: CLOSE_ADDEDIT_MASTER_PARTNER,
});

export const saveAddeditMasterPartner = (item, mode = "add") => ({
  type: SAVE_ADDEDIT_MASTER_PARTNER,
  payload: {
    item,
    mode,
  },
});

export const saveAddeditMasterPartnerSuccess = (data) => ({
  type: SAVE_ADDEDIT_MASTER_PARTNER_SUCCESS,
  payload: data,
});

export const saveAddeditMasterPartnerError = (error) => ({
  type: SAVE_ADDEDIT_MASTER_PARTNER_ERROR,
  payload: error,
});

export const deleteMasterPartner = (data, confirmed = false) => ({
  type: DELETE_MASTER_PARTNER,
  payload: {
    item: data,
    confirmed,
  },
});

export const deleteMasterPartnerSuccess = () => ({
  type: DELETE_MASTER_PARTNER_SUCCESS,
});

export const deleteMasterPartnerError = (error) => ({
  type: DELETE_MASTER_PARTNER_ERROR,
  payload: error,
});
