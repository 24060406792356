/* eslint-disable import/no-cycle */
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { DELETE, GET, POST, PUT } from "../../../helpers/apiHelper";
import {
  SuccessCreate,
  SuccessDelete,
  SuccessUpdate,
} from "../../../helpers/toastHelper";
import * as action from "./action";

export const getListState = (state) => state.partnerApp.list;

const loadMasterPartnerAsync = async (query) => GET(`/partner?${query}`);

const addMasterPartnerAsync = async (item) => POST(`/partner`, item);
const editMasterPartnerAsync = async (id, item) => PUT(`/partner/${id}`, item);
const deleteMasterPartnerAsync = async (id) => DELETE(`/partner/${id}`);

function* loadMasterPartnerList({ payload }) {
  try {
    const list = yield select(getListState);
    let param = [
      {
        name: "page",
        value: payload.selectedPage,
      },
      {
        name: "orderBy",
        value: list.orderBy,
      },
      {
        name: "sortedBy",
        value: list.sortedBy,
      },
      {
        name: "limit",
        value: payload.limit,
      },
    ];

    if (list.filter || "") {
      param = [
        ...param,
        ...[
          {
            name: "search",
            value: list.filter,
          },
          {
            name: "searchFields",
            value: list.filterFields,
          },
          {
            name: "searchJoin",
            value: list.searchJoin,
          },
        ],
      ];
    }
    const response = yield call(
      loadMasterPartnerAsync,
      param.map((t) => `${t.name}=${t.value}`).join("&")
    );
    yield put(action.loadMasterPartnerListSuccess(response));
  } catch (error) {
    yield put(action.loadMasterPartnerListError(error));
  }
}

function* saveAddeditMasterPartner({ payload }) {
  let response = null;
  try {
    if (payload.item) {
      if (payload.mode === "edit") {
        response = yield call(
          editMasterPartnerAsync,
          payload.item.id,
          payload.item
        );
      } else if (payload.mode === "add") {
        response = yield call(addMasterPartnerAsync, payload.item);
      } else {
        throw new Error("invalid mode");
      }
      yield put(action.saveAddeditMasterPartnerSuccess(response));
      yield put(action.loadMasterPartnerList());
      if (payload.mode === "add") {
        SuccessCreate();
      } else if (payload.mode === "edit") {
        SuccessUpdate();
      }
    } else {
      throw new Error("invalid save item, cannot be null");
    }
  } catch (error) {
    yield put(action.saveAddeditMasterPartnerError(error));
  }
}

function* deleteMasterPartner({ payload }) {
  try {
    if (payload && payload.confirmed) {
      if (payload.item) {
        yield call(deleteMasterPartnerAsync, payload.item.id);
        yield put(action.deleteMasterPartnerSuccess());
        yield put(action.loadMasterPartnerList());
        SuccessDelete();
      } else {
        throw new Error("invalid delete item, cannot be null");
      }
    }
  } catch (error) {
    yield put(action.deleteMasterPartnerError(error));
  }
}

export function* watchLoadMasterPartnerList() {
  yield takeEvery(action.LOAD_MASTER_PARTNER_LIST, loadMasterPartnerList);
}

export function* watchSaveAddeditMasterPartner() {
  yield takeEvery(action.SAVE_ADDEDIT_MASTER_PARTNER, saveAddeditMasterPartner);
}

export function* watchDeleteMasterPartner() {
  yield takeEvery(action.DELETE_MASTER_PARTNER, deleteMasterPartner);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadMasterPartnerList),
    fork(watchSaveAddeditMasterPartner),
    fork(watchDeleteMasterPartner),
  ]);
}
