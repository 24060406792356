import { combineReducers } from "redux";
import authUser from "./auth/reducer";
import countryApp from "./master/master-country/reducer";
import taskApp from "./master/master-task/reducer";
import provinceApp from "./master/master-province/reducer";
import taskDocumentApp from "./master/master-task-required-document/reducer";
import cityApp from "./master/master-city/reducer";
import companyApp from "./master/master-company/reducer";
import jobDescApp from "./master/master-job-description/reducer";
import partnerApp from "./master/master-partner/reducer";
import documentTypeApp from "./master/master-document/reducer";
import formFieldApp from "./master/master-form-field/reducer";
import clientApp from "./client/reducer";
import arsipApp from "./arsip/reducer";
import taskListApp from "./task/list-task/reducer";
import taskListClosedApp from "./task/list-closed/reducer";
import taskListRejectApp from "./task/list-reject/reducer";
import dashboardApp from "./master/dashboard/reducer";

const reducers = combineReducers({
  authUser,
  countryApp,
  taskApp,
  provinceApp,
  taskDocumentApp,
  cityApp,
  companyApp,
  jobDescApp,
  partnerApp,
  documentTypeApp,
  formFieldApp,
  clientApp,
  arsipApp,
  taskListApp,
  taskListClosedApp,
  taskListRejectApp,
  dashboardApp,
});

export default reducers;
